<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('modal.resolucion.sample.title')"
			:modelName="modelName"
			:dialogName="resolucionSampleModal"
			:componentHeaderName="'resolucion-form-header'"
			:onOk="onOkSampleModal"
			:onShow="onShowSampleModal"
		>
			<template slot="message" slot-scope="data">
					<v-row dense>
						<!-- IDRES -->
						<v-col cols="12">
							<pui-text-field
								:id="`idres-resolucion`"
								v-model="data.modalData.idres"
								:label="$t('resolucion.idres')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="10"
							></pui-text-field>
						</v-col>
					</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.resolucion.autconfig.title')"
			:modelName="modelName"
			:dialogName="resolucionAutConfigModal"
			:componentHeaderName="'resolucion-form-header'"
			:onOk="onOkAutConfigModal"
			:onCancel="onCancelAutConfigModal"
		>
			<template slot="message">
					<v-row dense>
						<!-- IDRES -->
						<v-col cols="12">
							<pui-field :label="$t('resolucion.modal.autocarga.confirm')" value=''></pui-field>
						</v-col>
					</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.resolucion.delcondiciones.title')"
			:modelName="modelName"
			:dialogName="resolucionDelCondicionesModal"
			:componentHeaderName="'resolucion-form-header'"
			:onOk="onOkDelCondicionesModal"
			:onCancel="onCancelDelCondicionesModal"
		>
			<template slot="message">
					<v-row dense>
						<!-- IDRES -->
						<v-col cols="12">
							<pui-field :label="$t('resolucion.modal.delcondiciones.confirm')" value=''></pui-field>
						</v-col>
					</v-row>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'resolucion-modals',
	data() {
		return {
			resolucionSampleModal: 'resolucionSampleAction',
			resolucionAutConfigModal:'resolucionAutConfigModal',
			resolucionDelCondicionesModal:'resolucionDelCondicionesModal'
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		},
		childModelName:{
			required: false,
			type: String
		},
		estadoCondiciones:{
			required: false,
			type: Object
		}
	},
	methods: {
		onOkSampleModal(modalData) {
			const params = {
				attr1: modalData.field1
			};

			const url = this.$store.getters.getModelByName(this.modelName).url.sampleUrl;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.resolucion.sampleaction.title');
			return new Promise((resolve) => {
				this.$puiRequests.postRequest(
					url,
					null,
					(response) => {
						// Valid response, do code and close modal
						console.log(response);
						resolve(true);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					},
					null,
					params
				);
			});
		},
		onShowSampleModal(modalData) {
			// Put here code to execute before open modal.
			// Examples:
			// - Watch pui-select/pui-datatable with events
			// - change values or set new values "this.$set(modalData,'field3', null)"
			// ...
			console.log(modalData);
		},
		onOkAutConfigModal(modalData){
			this.estadoCondiciones.visible=false;
			//enviar petición de limpiar y cargar condiciones automaticas (directamente en la lista en memoria)

			const body = {
				idareaopera: modalData.idareaopera,
				fecinicioaut:modalData.fecinicioaut,
				idnotif:modalData.idnotif
			};

			const url = this.$store.getters.getModelByName(this.childModelName).url.autocargacond;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('resolucion.modal.autocarga.title');
			return new Promise((resolve) => {
				this.$puiRequests.postRequest(
					url,
					body,
					(response) => {
						// Valid response, do code and close modal
						console.log(response);
						this.estadoCondiciones.visible=true;
						resolve(true);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.estadoCondiciones.visible=true;
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					}
				);
			});
		},
		onCancelAutConfigModal(){
			this.estadoCondiciones.visible=true;
			return new Promise((resolve) => {resolve(true);});
		},
		onOkDelCondicionesModal(modalData){
			this.estadoCondiciones.visible=false;
			return this.resetList(modalData);
		},
		onCancelDelCondicionesModal(){
			this.estadoCondiciones.visible=false;
			return new Promise((resolve) => {resolve(true);});
		},
		resetList(modalData) {
			const params = {
				idnotif: modalData.idnotif
			};

			const url = this.$store.getters.getModelByName(this.childModelName).url.resetList;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('resolucion.resetList.title');

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					url,
					params,
					response => {
						if (response.data == 'OK' && modalData.idres == null) {
							const objectPkRC = {};
							objectPkRC['idres'] = -1;
							const pkRCStr = JSON.stringify(objectPkRC);
							this.resPk = this.$puiUtils.utf8ToB64(pkRCStr);
							//this.showCondiciones = false;
							this.estadoCondiciones.visible=false;
							this.showCondicionesTipo = false;
							resolve(true);
						}
					},
					e => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiNotify.error(message, title);
						resolve(true);
					}
				);
			});
		}
	}
};
</script>
