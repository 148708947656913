<template>
	<div class="pui-form">
		<resolucion-modals :modelName="modelName" :childModelName="childModelName" :estadoCondiciones="estadoCondiciones"></resolucion-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement || showParentModelHeader">
			<notificacion-form-header v-if="showParentModelHeader" :modelPk="parentPk"></notificacion-form-header>
			<!--resolucion-form-header v-if="!isCreatingElement" :modelPk="modelPk"></resolucion-form-header-->
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded && ready">
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12 md12>
					<v-layout wrap>
						<!-- IDRES -->
						<v-flex xs12 md4 v-if="!isCreatingElement">
							<pui-text-field
								:id="`idres-resolucion`"
								v-model="model.idres"
								:label="$t('resolucion.idres')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="10"
							></pui-text-field>
						</v-flex>
						<!-- IDNOTIF -->
						<v-flex xs12 md4 v-if="!showParentModelHeader">
							<pui-select
								id="idnotif-resolucion"
								attach="idnotif"
								:label="$t('resolucion.idnotif')"
								toplabel
								clearable
								:disabled="formDisabled"
								required
								reactive
								v-model="model"
								modelName="vlupnotificacion"
								:itemsToSelect="[{ id: model.idnotif }]"
								:modelFormMapping="{ id: 'idnotif' }"
								itemValue="id"
								:itemText="(item) => `${item.id} - ${item.numdoc}`"
								:order="{ name: 'asc' }"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<!-- IDTIPRES -->
						<v-flex xs12 md6>
							<pui-select
								:id="`idtipres-resolucion`"
								attach="idtipres"
								:label="$t('resolucion.idtipres')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vlupmtiporesolucion"
								:itemsToSelect="itemsToSelectIdTipRes"
								reactive
								:modelFormMapping="{ idtipres: 'idtipres' }"
								itemValue="idtipres"
								:itemText="(item) => `${item.codresedi} - ${item.name}`"
								:order="{ name: 'asc' }"
								:filterMap="{ codresedi: model.codresedifilter }"
								:key="'idtipres-resolucion_' + refreshkey"
							></pui-select>
						</v-flex>
						<!-- FECINICIOAUT -->
						<v-flex xs12 md6>
							<pui-date-field
								:id="`fecinicioaut-resolucion`"
								:label="$t('resolucion.fecinicioaut')"
								v-model="model.fecinicioaut"
								toplabel
								:required="fechaautOblig"
								:disabled="formDisabled"
								time
							></pui-date-field>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<!-- IDAREAOPERA -->
						<v-flex xs12 md6>
							<pui-select
								:id="`idareaopera-resolucion`"
								attach="idareaopera"
								:label="$t('resolucion.idareaopera')"
								toplabel
								clearable
								:required="ubicacionOblig"
								:disabled="formDisabled"
								v-model="model"
								modelName="vlupmareaoperacion"
								:itemsToSelect="itemsToSelectIdAreaOpera"
								reactive
								:modelFormMapping="{ idareaopera: 'idareaopera' }"
								itemValue="idareaopera"
								:itemText="itemText"
								:order="{ name: 'asc' }"
								:fixedFilter="fixedFilterAreaopera"
							></pui-select>
						</v-flex>
						<!-- IDTERMINAL -->
						<v-flex xs12 md6 v-if="showSelectTerminal">
							<pui-select
								:id="`idterminal-resolucion`"
								attach="idterminal"
								:label="$t('resolucion.idterminal')"
								toplabel
								clearable
								:required="terminalOblig"
								:disabled="formDisabled"
								reactive
								v-model="model"
								modelName="mterminal"
								:itemsToSelect="[{ idterminal: model.idterminal }]"
								:modelFormMapping="{ idterminal: 'idterminal' }"
								itemValue="idterminal"
								:itemText="(item) => `${item.codterminal} - ${item.name}`"
								:order="{ name: 'asc' }"
								:fixedFilter="fixedFilterTerminal"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<!-- OBSERVACION -->
						<v-flex xs12>
							<pui-text-area
								ref="observacionesResolucion"
								:id="`observaciones-resolucion`"
								:label="$t('resolucion.observacion')"
								v-model="model.observacion"
								toplabel
								:required="observacionesOblig"
								:disabled="formDisabled"
								maxlength="350"
								rows="4"
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout dense v-if="estadoCondiciones.visible">
						<v-flex xs12>
							<pui-field-set :title="getCondiciones" id="id-condiciones-fs">
								<v-layout dense>
									<v-flex xs10>
										<!--
								<pui-master-detail
										componentName="resolucioncondicion-grid"
										:parentModelName="modelName"
										:parentPk="pk"
										:parentPkChildFk="{ idres: 'idres' }"
										:modalDialog="isModalDialog"
										:formDisabled="true"
										:parentModel="model"
								></pui-master-detail>
								-->
										<!--<pui-form-datatable
											id="resolucioncondicionquickedit-form"
											:parentModelName="modelName"
											:parentPk="this.resPk"
											:parentPkChildFk="{ idres: 'idres' }"
											:disabled="formDisabled"
											:modelName="this.childModelName"
											formComponentName="resolucioncondicionquickedit-form"
											:parentModel="model"
											formPosition="top"
											showPaginator="false"
											:manageInMemory="true"
										></pui-form-datatable>-->
										<pui-quick-edition-datatable
											id="resolucioncondicionquickedit-form"
											:parentModelName="modelName"
											:parentPk="this.resPk"
											:parentPkChildFk="{ idres: 'idres' }"
											:disabled="formDisabled"
											:modelName="this.childModelName"
											formComponentName="resolucioncondicionquickedit-form"
											:parentModel="model"
											formPosition="top"
											showPaginator="false"
										></pui-quick-edition-datatable>
									</v-flex>
								</v-layout>
							</pui-field-set>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
			<notificacion-pdf-dialog
				:titleText="$t('action.notificacion.pdfAutorizacion')"
				:messageText="$t('pui9.delete.confirm')"
				:modelName="modelName"
				:dialogName="dialogNamePdfNotificacion"
				:okText="okTextFirmarPdf"
				:cancelText="$t('pui9.cancel')"
				:extraButton="verPdf"
				:onOk="firmarDocumento"
				:onExtraButton="verDocumento"
				:showDialogProp="showDialog"
				widthDialog="600"
			>
				<template slot="message">
					<v-overlay :value="loading" :absolute="true">
						<v-progress-circular indeterminate size="64"></v-progress-circular>
					</v-overlay>
					<v-row dense>
						<!-- IDNOTIF -->
						<v-col cols="12">
							<pui-field :label="$t('action.notificacion.pdfAutorizacion.message')" value=""></pui-field>
						</v-col>
					</v-row>
				</template>
			</notificacion-pdf-dialog>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
				<!-- <pui-form-footer-btns v-else :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns> -->
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import resolucionActions from './ResolucionActions';
import resolucionModals from './ResolucionModals.vue';
import AutoScript from '../../assets/autoscript';
import notificacionPdfDialog from '../../components/notificacion/NotificacionPdfDialog.vue';

//import puiFormDatatable from '../pui9override/PuiFormDatatable.vue';

export default {
	name: 'resolucion-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'resolucion-modals': resolucionModals,
		notificacionPdfDialog
	},
	data() {
		return {
			modelName: 'resolucion',
			actions: resolucionActions.formActions,
			parentModelName: 'notificacionres',
			parentPk: null,
			childModelName: 'resolucioncondicionquick',
			showParentModelHeader: false,
			resPk: this.pk,
			//showCondiciones: false,
			estadoCondiciones: { visible: false },
			condicionesCargadas: false,
			znnPreguntarSiLimpiarCond: false,
			showCondicionesTipo: false,
			ubicacionOblig: false,
			terminalOblig: false,
			fechaautOblig: false,
			observacionesOblig: false,
			refreshkey: 0,
			estadoescala: null,
			areaoperafilter: '{ codpue: model.codpue }',
			fechaAutpordefecto: null,
			notifPK: null,
			showDialog: true,
			dialogNamePdfNotificacion: 'firmarPdfNotificacion',
			firmaResolucion: null,
			originalsave: null,
			loading: false,
			showSelectTerminal: null,
			ready: false
		};
	},
	watch: {
		'model.fecinicioaut'(value) {
			if (!this.$appUtils.isEmpty(value)) {
				this.prepararCondiciones();
			} else {
				this.estadoCondiciones.visible = false;
			}
		}
	},
	methods: {
		afterGetData() {
			this.ready = false;
			this.originalsave = this.save;
			this.getVariableFirma().then(() => {
				if (this.firmaResolucion == 1) this.save = this.savePuiModified;
				this.getVariableApareceDesplegableTerminal().then(() => {
					this.ready = true;
				});
			});
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);
			if (parentModel != null) {
				this.parentPk = parentModel.pk;
				this.model.idnotif = this.parentPk.idnotif;
				this.showParentModelHeader = true;
				if (this.model.idres == null) {
					this.getEscala();
					this.resetList();
					this.getUbicacionNotificacion();
					this.getTerminalNotificacion();
					this.selectTipoResolucion();
					this.fechaAutpordefecto = this.model.fecinicioaut;
					this.model.fecinicioaut = null;
				} else {
					//this.showCondiciones = true;
					this.estadoCondiciones.visible = false;
					this.showCondicionesTipo = true;
					this.formDisabled = true;
				}
			}
		},
		getUbicacionNotificacion() {
			const params = {
				idnotif: this.model.idnotif
			};

			const url = this.$store.getters.getModelByName('notificacion').url.getUbicacion;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('resolucion.getUbicacion.title');
			this.$puiRequests.getRequest(
				url,
				params,
				(response) => {
					this.$set(this.model, 'idareaoperanotif', response.data);
					this.refreshkey++;
				},
				(e) => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiNotify.error(message, title);
				}
			);
		},
		getTerminalNotificacion() {
			const params = {
				idnotif: this.model.idnotif
			};

			const url = this.$store.getters.getModelByName('notificacion').url.getTerminal;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('resolucion.getTerminal.title');
			this.$puiRequests.getRequest(
				url,
				params,
				(response) => {
					if (response && response.status == 200) {
						this.$set(this.model, 'idterminalnotif', response.data);
						this.refreshkey++;
					}
				},
				(e) => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiNotify.error(message, title);
				}
			);
		},
		getEscala() {
			const params = {
				idnotif: this.model.idnotif
			};

			const url = this.$store.getters.getModelByName('notificacion').url.getEscala;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('resolucion.getEscala.title');
			this.$puiRequests.getRequest(
				url,
				params,
				(response) => {
					this.$set(this.model, 'codpue', response.data.codpue);
					this.estadoescala = response.data.codestado;
					if (
						!this.$appUtils.isEmpty(this.estadoescala) &&
						!(this.estadoescala === 'AC' || this.estadoescala === 'AU' || this.estadoescala === 'IN' || this.estadoescala === 'FI')
					) {
						this.$set(this.model, 'codresedifilter', 'ZNN');
					}

					this.$set(this.model, 'listamuelles', response.data.muelles);

					this.refreshkey++;
				},
				(e) => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiNotify.error(message, title);
				}
			);
		},
		resetList() {
			const params = {
				idnotif: this.model.idnotif
			};

			const url = this.$store.getters.getModelByName(this.childModelName).url.resetList;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('resolucion.resetList.title');
			this.$puiRequests.getRequest(
				url,
				params,
				(response) => {
					if (response.data == 'OK' && this.model.idres == null) {
						const objectPkRC = {};
						objectPkRC['idres'] = -1;
						const pkRCStr = JSON.stringify(objectPkRC);
						this.resPk = this.$puiUtils.utf8ToB64(pkRCStr);
						//this.showCondiciones = false;
						this.estadoCondiciones.visible = false;
						this.showCondicionesTipo = false;
					}
				},
				(e) => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiNotify.error(message, title);
				}
			);
		},
		selectTipoResolucion() {
			const puiVariableUrl = '/puivariable/getVariable/RESOLUCION_TIPO_RESOLUCION_POR_DEFECTO';
			this.$puiRequests.getRequest(
				puiVariableUrl,
				null,
				(response) => {
					//Si el parámetro RESOLUCION_TIPO_RESOLUCION_POR_DEFECTO vale 1, seleccionamos en la lupa de tipo resolucion
					//el valor ZEN
					if (response.data && response.data === 1) {
						this.model.idtipres = 2;
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		prepararCondiciones() {
			var cargarCondiciones =
				this.showCondicionesTipo && !this.$appUtils.isEmpty(this.model.fecinicioaut) && !this.$appUtils.isEmpty(this.model.idareaopera);

			if (this.formDisabled) {
				this.estadoCondiciones.visible = cargarCondiciones;
				return;
			}

			if (cargarCondiciones) {
				//this.showCondiciones=true;
				//this.estadoCondiciones.visible=true;

				//preguntar quieren limpiar las condiciones y recargar las automaticas
				const row = this.model;

				this.$puiEvents.$emit('pui-modalDialogForm-resolucionAutConfigModal-' + this.modelName + '-show', row);
				this.condicionesCargadas = true;
			} else {
				if (!this.showCondicionesTipo && !this.znnPreguntarSiLimpiarCond) {
					//tipo es ZNN y no debo preguntar si limpiar...limpiar directamente
					this.estadoCondiciones.visible = false;
					this.resetList();
				} else {
					if (this.condicionesCargadas) {
						//preguntar si quieren limpiar las condiciones
						const row = this.model;
						this.$puiEvents.$emit('pui-modalDialogForm-resolucionDelCondicionesModal-' + this.modelName + '-show', row);
					}
				}
			}
		},
		savePuiModified() {
			this.setAfterSaveActions();
			this.beforeSave && this.beforeSave();
			this.formValidationErrors = false;
			if (this.$refs.form && this.$refs.form.validate && !this.$refs.form.validate()) {
				this.formValidationErrors = true;
				this.$puiNotify.error(this.$t('pui9.error.form_errors'));
				this.resetAfterSaveActions();
				return;
			}
			this.validateCondiciones().then((res) => {
				if (res) this.abrirDialog();
			});
		},
		abrirDialog() {
			if (this.firmaResolucion == 1) this.$puiEvents.$emit('pui-modalDialogForm-firmarPdfNotificacion-' + this.modelName + '-show', this.model);
		},
		verDocumento(modalData) {
			this.loading = true;
			const newresolucion = {
				idres: this.model.idres,
				idnotif: this.model.idnotif,
				idtipres: this.model.idtipres,
				fecinicioaut: this.model.fecinicioaut,
				observacion: this.model.observacion,
				idareaopera: this.model.idareaopera,
				idterminal: this.model.idterminal,
				fecalta: this.model.fecalta,
				usralta: this.model.usralta,
				fecmodif: this.model.fecmodif,
				usrmodif: this.model.usrmodif
			};

			const objectPk = { idnotif: modalData.idnotif, newresolucion };
			const urlController = this.$store.getters.getModelByName('notificacion').url.getPDFAutorizacionFromModal;
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t(`action.seePDFAutorizacion`);

			this.$puiRequests.downloadFileRequest(
				'get',
				urlController,
				objectPk,
				(response) => {
					var blob = new Blob([response.data], { type: 'application/pdf' });
					var blobURL = URL.createObjectURL(blob);
					window.open(blobURL);
					this.loading = false;
				},
				() => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					var message = this.$puiI18n.t('puiaction.notifyError');
					this.$puiNotify.error(message, title);
					this.showDialog = false;
				}
			);
		},
		firmarDocumento(modalData) {
			this.showDialog = true;
			this.loading = true;
			this.notifPK = { idnotif: modalData.idnotif };
			const newresolucion = {
				idres: modalData.idres,
				idnotif: modalData.idnotif,
				idtipres: modalData.idtipres,
				fecinicioaut: modalData.fecinicioaut,
				observacion: modalData.observacion,
				idareaopera: modalData.idareaopera,
				idterminal: modalData.idterminal,
				fecalta: modalData.fecalta,
				usralta: modalData.usralta,
				fecmodif: modalData.fecmodif,
				usrmodif: modalData.usrmodif
			};

			const objectPk = { idnotif: modalData.idnotif, newresolucion };
			const urlgetPdfFirm = this.$store.getters.getModelByName('notificacion').url.getPDFAutorizacionFirmadaFromModal;
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$t(`action.getPDFAutorizacion`);
			this.$puiRequests.getRequest(
				urlgetPdfFirm,
				objectPk,
				(response) => {
					var params =
						'signaturePage=-1\nsignaturePositionOnPageLowerLeftX= 250\nsignaturePositionOnPageLowerLeftY= 250\nsignaturePositionOnPageUpperRightX= 470\nsignaturePositionOnPageUpperRightY= 200';

					AutoScript.cargarAppAfirma();
					AutoScript.sign(response.data, 'SHA512withRSA', 'Adobe PDF', params, this.showSignResultCallback, this.errorSignCallBack);
				},
				() => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					var message = this.$puiI18n.t('action.notificacion.pdfAutorizacion.message.error');
					this.$puiNotify.error(message, title);
					this.showDialog = false;
					this.loading = false;
				}
			);
		},
		errorSignCallBack(type, message) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			this.$puiNotify.error(message);
			this.showDialog = false;
			this.loading = false;
			this.back();
		},
		showSignResultCallback(signatureB64, shouldStayInPage) {
			this.saving = true;
			if (this.firmaResolucion == 1) shouldStayInPage = true;
			if (this.method === 'create' || this.method === 'copy') {
				this.insertPuiModified(shouldStayInPage, this.modelDependency.url.insert).then((res) => {
					if (res) this.resolvedPromiseUploadFile(signatureB64);
				});
			} else if (this.method === 'update') {
				this.updatePuiModified(shouldStayInPage, this.modelDependency.url.update).then((res) => {
					if (res) this.resolvedPromiseUploadFile(signatureB64);
				});
			}
		},
		uploadFile(signatureB64) {
			this.loading = true;
			const data = { pk: this.notifPK, encoded: signatureB64 };
			const urlUpload = this.$store.getters.getModelByName('notificacion').url.upload;

			return new Promise((resolve) => {
				this.$puiRequests.postRequest(
					urlUpload,
					data,
					(response2) => {
						// Valid response, do code and close modal
						if (response2.status === 200) {
							resolve(true);
						}
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response2 && e.response2.data) {
							message = e.response2.data.message;
						}
						this.showDialog = false;
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message);
						resolve(false);
					},
					null,
					this.notifPK
				);
			});
		},
		resolvedPromiseUploadFile(signatureB64) {
			this.uploadFile(signatureB64).then((r) => {
				if (r) {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					var message = this.$puiI18n.t('action.notificacion.pdfAutorizacion.message.success');
					this.$puiNotify.success(message);
					this.showDialog = false;
					this.loading = false;
					this.back();
				}
			});
		},
		insertPuiModified(shouldStayInPage, url) {
			return new Promise((resolve) => {
				this.$puiRequests.postRequest(
					url,
					this.model,
					(response) => {
						this.saving = false;
						this.$puiEvents.$emit(`onPui-insertRow-dataTable-${this.modelDependency.name}`, response.data);
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.afterSave && this.afterSave(response.data);
						if (!(shouldStayInPage === true)) {
							if (this.internal.afterInsertGoToUpdate) {
								this.goToUpdate(response.data);
							} else if (this.internal.afterInsertGoToCreate) {
								// Router not allow to go same router with same parameters
								this.refreshRouter();
							} else {
								this.back();
							}
							this.resetAfterSaveActions();
						}
						resolve(true);
					},
					(error) => {
						this.resetAfterSaveActions();
						this.saving = false;
						this.onSaveError && this.onSaveError();
						this.onError(error);
						resolve(false);
					}
				);
			});
		},
		updatePuiModified(shouldStayInPage, url) {
			let updateHeaders = {};
			if (this.dtohash) {
				updateHeaders.dtohash = this.dtohash;
			}
			const langStore = this.$store.getters.getRequestLang;
			if (langStore !== null) {
				updateHeaders.lang = langStore;
			}
			return new Promise((resolve) => {
				this.$puiRequests.putRequest(
					url,
					this.model,
					(response) => {
						this.saving = false;
						this.$puiEvents.$emit(`onPui-editRow-dataTable-${this.modelDependency.name}`, response.data);
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.afterSave && this.afterSave(response.data);
						if (!(shouldStayInPage === true)) {
							if (this.internal.afterUpdateGoToUpdate) {
								// Router not allow to go same router with same parameters
								this.refreshRouter();
							} else if (this.internal.afterUpdateGoToCreate) {
								this.goToCreate();
							} else {
								this.back();
							}
						}
						this.resetAfterSaveActions();
						resolve(true);
					},
					(error) => {
						this.resetAfterSaveActions();
						this.saving = false;
						this.onSaveError && this.onSaveError();
						if (error.response && error.response.data && error.response.data.internalCode === 807) {
							this.$puiEvents.$emit('onPuiShowConcurrencyErrorModal');
						} else {
							this.onError(error);
						}
						resolve(false);
					},
					updateHeaders
				);
			});
		},
		getVariableFirma() {
			const puiVariableUrl = '/puivariable/getVariable/FIRMAR_RESOLUCION';

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					puiVariableUrl,
					null,
					(response) => {
						if (response && response.status == 200) {
							this.firmaResolucion = response.data;
							resolve(true);
						}
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', {
							error: error,
							vue: this
						});
						resolve(false);
					}
				);
			});
		},
		getVariableApareceDesplegableTerminal() {
			const puiVariableUrl = '/puivariable/getVariable/RESOLUCION_APARECE_TERMINAL_AUTORIZADA';

			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					puiVariableUrl,
					null,
					(response) => {
						if (response && response.status == 200) {
							this.showSelectTerminal = response.data;
							resolve(true);
						}
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', {
							error: error,
							vue: this
						});
						resolve(false);
					}
				);
			});
		},
		validateCondiciones() {
			const dto = {
				idres: this.model.idres,
				idnotif: this.model.idnotif,
				idtipres: this.model.idtipres,
				fecinicioaut: this.model.fecinicioaut,
				observacion: this.model.observacion,
				idareaopera: this.model.idareaopera,
				fecalta: this.model.fecalta,
				usralta: this.model.usralta,
				fecmodif: this.model.fecmodif,
				usrmodif: this.model.usrmodif
			};
			const urlvalidatecondiciones = this.$store.getters.getModelByName(this.modelName).url.validateCondiciones;
			return new Promise((resolve) => {
				this.$puiRequests.postRequest(
					urlvalidatecondiciones,
					dto,
					(response) => {
						if (response.status == 200) resolve(true);
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', {
							error: error,
							vue: this
						});
						resolve(false);
					},
					null,
					null
				);
			});
		}
	},
	computed: {
		itemsToSelectIdTipRes() {
			return [{ idtipres: this.model.idtipres }];
		},
		itemsToSelectIdAreaOpera() {
			return [{ idareaopera: this.model.idareaopera }];
		},
		itemText() {
			var suff = !this.$appUtils.isEmpty(this.model.idareaoperanotif) ? this.$t('resolucion.idareaopera.selnotif') : '';
			return (item) => `${item.codareaopera} - ${item.name}${this.model.idareaoperanotif == item.idareaopera ? suff : ''} `;
		},
		getCondiciones() {
			return this.$t('resolucion.fs.condiciones');
		},
		condicionesVisibles() {
			var fs = document.getElementById('id-condiciones-fs');
			var legend = fs ? fs.firstChild : null;
			if (legend != null) legend.innerHTML = "<span style='color:red'>" + this.$t('resolucion.fs.condiciones') + ' *</span>';
			return true;
		},
		fixedFilterAreaopera() {
			const filter = {
				groups: [],
				groupOp: 'and',
				rules: []
			};
			if (!this.$appUtils.isEmpty(this.model.listamuelles)) {
				filter.rules.push({ field: 'codareaopera', op: 'in', data: this.model.listamuelles.map((muelle) => muelle.codigo).join() });
			} else if (!this.$appUtils.isEmpty(this.model.codpue)) {
				filter.rules.push({ field: 'codpue', op: 'eq', data: this.model.codpue });
			}

			return filter;

			//return this.areaoperafilter;
		},
		fixedFilterTerminal() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'fecbaja', op: 'eq', data: null }]
					}
				],
				groupOp: 'and',
				rules: []
			};
		},
		okTextFirmarPdf() {
			return this.$t('action.notificacion.pdfAutorizacion.firmar');
		},
		verPdf() {
			return this.$t('action.notificacion.pdfAutorizacion.verPdf');
		},
		getSave() {
			return this.save;
		}
	},
	mounted() {
		this.$puiEvents.$on(`onPuiSelect_selectedItems-idtipres-resolucion`, (newVal) => {
			var lupamodel = newVal.model;
			if (lupamodel || lupamodel != null) {
				this.model.codresedi = lupamodel.codresedi;

				this.condicionesOblig = false;
				this.ubicacionOblig = false;
				this.terminalOblig = false;
				this.fechaautOblig = false;
				this.observacionesOblig = false;

				//var fs = document.getElementById('id-condiciones-fs');
				//var legend = (fs) ? fs.firstChild: null;

				if (lupamodel.codresedi == 'ZED' || lupamodel.codresedi == 'ZEN') {
					this.condicionesOblig = true;
					this.fechaautOblig = true;
					this.terminalOblig = true;
					if (this.$appUtils.isEmpty(this.model.fecinicioaut)) this.model.fecinicioaut = this.fechaAutpordefecto;
					//if(legend!=null) legend.innerHTML = "<span style='color:red'>" + this.$t('resolucion.fs.condiciones') + ' *</span>';
					this.showCondicionesTipo = true;
					if (!this.$appUtils.isEmpty(this.model.idareaoperanotif)) this.$set(this.model, 'idareaopera', this.model.idareaoperanotif);
					if (!this.$appUtils.isEmpty(this.model.idterminalnotif)) this.$set(this.model, 'idterminal', this.model.idterminalnotif);
				} else {
					//if(legend!=null) legend.innerHTML = this.$t('resolucion.fs.condiciones');
					this.showCondicionesTipo = false;
				}

				if (lupamodel.codresedi == 'ZED') {
					this.ubicacionOblig = true;
				} else if (lupamodel.codresedi == 'ZNN') {
					this.observacionesOblig = true;
					this.model.fecinicioaut = '';
				}

				this.prepararCondiciones();
			} else {
				this.estadoCondiciones.visible = false;
			}
		});
		this.$puiEvents.$on(`onPuiSelect_selectedItems-idareaopera-resolucion`, (newVal) => {
			var lupamodel = newVal.model;
			if (lupamodel || lupamodel != null) {
				this.prepararCondiciones();
			} else {
				this.estadoCondiciones.visible = false;
			}
		});
	},
	destroyed() {
		this.$puiEvents.$off(`onPuiSelect_selectedItems-idtipres-resolucion`);
		this.$puiEvents.$off(`onPuiSelect_selectedItems-idareaopera-resolucion`);
	}
};
</script>
